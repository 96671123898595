<template>
  <b-col
    :lg="whichPage === 'listings' || whichPage === 'list_event' ? 12 : 4"
    class="d-flex align-items-center auth-bg px-2"
    :class="['auth-login', 'auth-registration', 'company-listings-page'].includes($route.name) ? 'p-lg-5' : 'p-lg-2'"
  >
    <b-col
      :sm="['auth-login', 'auth-registration', 'company-listings-page'].includes($route.name) ? 8 : 12"
      :md="['auth-login', 'auth-registration', 'company-listings-page'].includes($route.name) ? 6 : 12"
      :lg="12"
      class="px-xl-2 mx-auto"
    >
      <b-card-title
        title-tag="h2"
        class="font-weight-bold mb-1"
      >
        Create An Account
      </b-card-title>
      <b-card-text class="mb-2">
        To register for an account, please fill up the form below.
      </b-card-text>

      <!-- form -->
      <validation-observer ref="registerForm">
        <b-form class="auth-register-form mt-2">
          <!-- Account Type -->
          <b-form-group
            label="Account Type"
            label-for="register-account-type"
          >
            <validation-provider
              #default="{ errors }"
              name="Account Type"
              rules="required"
            >
              <v-select
                id="register-account-type"
                v-model="account_type"
                name="register-account-type"
                placeholder="Choose your account type"
                :options="account_types"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- employer list -->
          <b-form-group
            :class="showEmployerList ? '': 'd-none'"
            label="Company Name(s)"
            label-for="register-account-company"
          >
            <validation-provider
              v-if="showEmployerList"
              #default="{ errors }"
              name="Company Name"
              rules="required"
            >
              <CompanySearch
                id="register-account-company"
                v-model="selected_companies"
                name="register-account-company"
                :multiple="true"
                :state="errors.length > 0 ? false : null"
                @company-selected="selectedCompanies"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- fullname -->
          <b-form-group
            :label="name_holders"
            label-for="register-fullname"
          >
            <validation-provider
              #default="{ errors }"
              :name="name_holders"
              rules="required|alpha-spaces"
            >
              <b-form-input
                id="register-fullname"
                v-model="fullname"
                name="register-fullname"
                :state="errors.length > 0 ? false : null"
                :placeholder="name_holders"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- phone number -->
          <b-form-group
            label="Contact Number"
            label-for="register-phone-number"
          >
            <validation-provider
              #default="{ errors }"
              name="Contact Number"
              rules="required|length:8"
            >
              <b-form-input
                id="register-phone-number"
                v-model="phone_number"
                name="register-phone-number"
                :state="errors.length > 0 ? false : null"
                placeholder="87965421"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- email -->
          <b-form-group
            label="Email Address"
            label-for="register-email"
          >
            <validation-provider
              #default="{ errors }"
              name="Email Address"
              rules="required|email"
            >
              <b-form-input
                id="register-email"
                v-model="userEmail"
                name="register-email"
                :state="errors.length > 0 ? false : null"
                placeholder="user@example.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- password -->
          <b-form-group
            label-for="register-password"
            label="Password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="password"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="register-password"
                  v-model="userPassword"
                  autocomplete="new-password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  name="register-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- confirm password -->
          <b-form-group
            label-for="register-confirm-password"
            label="Confirm Password"
          >
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="register-confirm-password"
                  v-model="confirmPassword"
                  autocomplete="new-password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  name="register-confirm-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox
              id="register-terms-policy"
              v-model="isTermsConditionAccepted"
              name="checkbox-1"
            >
              <span v-if="popup_agreements">
                I agree to
                <b-link
                  class="text-pantone-445"
                  href="/loa"
                  target="_blank"
                  rel="nofollow"
                >e2i's Letter Of Agreement</b-link>

                <span> and </span>
                <b-link
                  class="text-pantone-445"
                  href="https://www.e2i.com.sg/terms-of-use/"
                  target="_blank"
                  rel="nofollow"
                >Terms of Use</b-link>

              </span>

              <span v-else>
                I agree to the
                 <b-link
                  class="text-pantone-445"
                  href="https://www.e2i.com.sg/terms-of-use/"
                  target="_blank"
                  rel="nofollow"
                >Terms of Use</b-link>
              </span>
            </b-form-checkbox>
          </b-form-group>
          <SpinnerBtn v-if="isLoading" />
          <b-button
            v-else
            variant="primary"
            block
            :disabled="
              !isTermsConditionAccepted ||
                !userEmail.length > 4 ||
                !userPassword.length > 7
            "
            type="submit"
            @click.prevent="register"
          >
            Sign up
          </b-button>
        </b-form>
      </validation-observer>

      <p class="text-center mt-2">
        <span>Already have an account?</span>
        <b-link :to="{ name: 'auth-login' }">
          <span>&nbsp;Sign in instead</span>
        </b-link>
      </p>
    </b-col>
    <b-modal
      v-model="pop_terms"
      centered
      hide-footer
      title="Terms and Conditions"
      ok-title="I have read the Terms and Conditions"
      size="lg"
      scrollable
      ok-only
    >
      <Terms />
    </b-modal>
    <b-modal
      v-model="pop_priv"
      centered
      hide-footer
      title="Privacy Policy"
      ok-title="I have read Privacy Policy"
      size="lg"
      scrollable
      ok-only
    >
      <Privacy />
    </b-modal>
  </b-col>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BCardTitle,
  BCardText,
  BModal,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import {
  required, email, password, alphaSpaces,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'
import useJwt from '@/auth/jwt/useJwt'
import CompanySearch from '@/components/CompanySearch.vue'

import Terms from '@/views/docs/Terms.vue'
import Privacy from '@/views/docs/Privacy.vue'

export default {
  components: {
    VSelect,
    Privacy,
    Terms,
    BModal,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    SpinnerBtn,
    CompanySearch,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  props: {
    accountType: { type: String, default: null },
    whichPage: { type: String, default: 'normal' },
    eventId: { type: String, default: '' },
    extraData: { type: Array, default: null },
  },
  data() {
    return {
      popup_agreements: true,
      data_privacy: true,
      name_holders: 'Full name',
      name: '',
      userEmail: '',
      userPassword: '',
      confirmPassword: '',
      isTermsConditionAccepted: false,
      showEmployerList: false,
      isDataPrivacy: false,
      location: '',
      pop_terms: false,
      pop_priv: false,
      sanitized_account_type: '',
      account_type: '',
      account_types: ['Applicant', 'Direct Employer', 'Recruitment Agency'],
      isLoading: false,
      phone_number: '',
      selected_companies: [],

      required,
      email,
      password,
      alphaSpaces,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    account_type(val) {
      this.company_type(val)
      const allAccountTypes = {
        'Direct Employer': this.$AccountTypes.EMPLOYER,
        'Recruitment Agency': this.$AccountTypes.AGENCY,
        'Educational Institution': this.$AccountTypes.EDUCATION_INSTITUTION,
        Applicant: this.$AccountTypes.APPLICANT,
      }
      this.sanitized_account_type = allAccountTypes[val]
    },
  },
  created() {
    this.account_type = this.accountType

    if (this.whichPage === 'listings' || this.whichPage === 'list_event') {
      this.company_type(this.$AccountTypes.APPLICANT)
      this.popup_agreements = false
    }
    this.userEmail = this.$route.query.email_address || ''
    this.fullname = this.$route.query.fullname || ''
  },
  methods: {

    company_type(companyType) {
      this.showEmployerList = false
      this.account_type = companyType
      if (
        companyType === 'Direct Employer'
        || companyType === 'Recruitment Agency'
      ) {
        // this.data_privacy = false
        this.isDataPrivacy = true
        // this.name_holders = 'Company Name'
        this.showEmployerList = true
      } else if (companyType === 'Educational Institution') {
        // this.data_privacy = false
        this.isDataPrivacy = true
        // this.name_holders = 'Institution Name'
        this.showEmployerList = true
      } else {
        // this.data_privacy = true
        this.isDataPrivacy = false
        this.name_holders = 'Full Name'
      }
    },
    initializeJWT(location = 'Unknown') {
      const autoConfirm = true
      // eslint-disable-next-line prefer-destructuring
      const selected_companies = this.selected_companies
      if (!this.sanitized_account_type) {
        this.sanitized_account_type = this.$AccountTypes.APPLICANT
      }

      const userDetails = {

        fullname: this.fullname,
        additional_companies:
            selected_companies.length > 1 ? selected_companies.splice(1) : [],
        phone_number: this.phone_number,
        email_address: this.userEmail.toLowerCase(),
        password: this.userPassword,
        company_id:
            this.account_type === this.$AccountTypes.APPLICANT ? null : this.selected_companies[0],
        account_type: this.sanitized_account_type,
      }
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt
            .register({ user: userDetails })
            .then(response => {
              useJwt.setToken(response.data.token) //
              this.$emit('login-state', true)
              this.$emit('register-process', {
                fullname: this.fullname,
                email_address: this.userEmail.toLowerCase(),
              })
              this.isLoading = false
              if (autoConfirm) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Successful',
                    icon: 'CheckCircleIcon',
                    text: 'You are now logged in to your account.',
                    variant: 'success',
                  },
                })
                localStorage.setItem('accessToken', response.data.token) // Update user details
                this.$store.commit('auth/UPDATE_USER_INFO', response.data, {
                  root: true,
                })

                if (this.$route.name.includes('listings-page') || this.$route.name === 'page-event') {
                  this.$emit('user-registered')
                } else {
                  const link = '/'
                  this.$router.push(link)
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Successful',
                    icon: 'AlertCircleIcon',
                    text: 'Please check your email address to confirm your account.You might need to check the spam folder for the confirmation email..',
                    variant: 'warning',
                  },
                })
              }
            })
            .catch(error => {
              this.isLoading = false
              const errorResponse = error.response

              if (errorResponse) {
                const dataError = errorResponse.data.errors.body
                if (dataError) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Registration Failed',
                      icon: 'XCircleIcon',
                      text: dataError[0],
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Registration Failed',
                    icon: 'XCircleIcon',
                    text: 'Could not process your request at this time. Please try again later.',
                    variant: 'danger',
                  },
                })
              }
            })
        } else {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sign Up Error',
              icon: 'AlertCircleIcon',
              text: 'Please correct all the form errors before proceeding',
              variant: 'danger',
            },
          })
        }
      })
    },
    selectedCompanies(companies) {
      this.selected_companies = companies.map(company => company.id)
    },

    register() {
      // this.isLoading = true
      this.initializeJWT()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
